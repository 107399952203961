import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { withUnpublishedPreview } from "gatsby-source-prismic"

import BlogPostTemplate from "../Templates/blogPostTemplate"
import Layout from "../containers/Layout"
import SEO from "../components/SEO"
import homeTemplate from "./index"
import { ContentWrapper } from "../containers/alpacked.style"

const CaseStudy = ({ location }) => {
  useEffect(() => {
    navigate(
      `/Unleashing-streaming-startups-potential-with-DevOps?utm_source=(direct)&utm_medium=pdf&utm_content==case_study_unleashing_streaming_startups_potential`
    )
  }, [])

  return (
    <>
      <SEO
        location={location}
        title="Alpacked DevOps Company"
        shortDesc="Unleashing streaming startups, potential with DevOps"
        description="Unleashing streaming startups, potential with DevOps"
      />
      <Layout>
        <ContentWrapper>
          <iframe
            src="/Unleashing-streaming-startups-potential-with-DevOps.pdf"
            width="100%"
            height="1000"
            title="Unleashing streaming startups, potential with DevOps"
          />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default withUnpublishedPreview(CaseStudy, {
  templateMap: {
    page: BlogPostTemplate,
    homepage: homeTemplate,
    prismicPage: BlogPostTemplate,
    prismicHomepage: homeTemplate,
  },
})
